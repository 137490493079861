import { authHeader } from './auth-header';
import {globalVariable} from "@/helpers/global-variables";
import {userService} from "@/helpers/fakebackend/user.service";

export const serviceService = {
    getAll
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `service`, requestOptions).then(handleResponse);
}

async function handleResponse(response) {
    if (response.ok) {
        return response.json();
    }else{
        if (response.status === 401) {
            userService.logout();
        }
        if (response.status >= 500) {
            throw {code: globalVariable.api.ERROR_500};
        }
        throw await response.json();
    }
}
