<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {serviceService} from "@/helpers/fakebackend/service.service";
import ServiceDetails from "@/views/pages/services/details/service-details";
import {ToCSV} from "@/helpers/ToCSV";
import {requestMessage} from "@/helpers/request-message";
/**
 * Form-element component
 */
export default {
  page: {
    title: "Leistungen",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, ServiceDetails },
  data() {
    return {
      title: "Leistungen",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Leistungen",
          active: true,
        },
      ],
      services: [],
      filteredServices: [],
      nativFilteredServices: [],
      service: {},
      tableTitle: "Leistungen",
      tableItems: [
        {
          text: "Tables",
          href: "/"
        },
        {
          text: "Advanced",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 200, 1000],
      filter: null,
      filters: {
        all: "",
        storage: null,
        compute: null,
        network: null,
        database: null,
        others: null,
      },
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      status: "",
      period: "",
      periodList: [],
      statusActivated: false,
      periodActivated: false,
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "startDate", label: "Start | Ende", sortable: true },
        { key: "productGroupName", label: "Produkt", sortable: true },
        { key: "rate", label: "Preis pro Stunde", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "productLink", label: "Cloud", sortable: false },
      ],
      globalMessage: {message: "", type: "danger"},
      isLoading: true,
      isViewService: false,
      isFilterOpen: false,
    };
  },
  computed: {
    rows() {
      return this.services.length;
    }
  },
  mounted() {
    serviceService.getAll().then(data => {
      this.services = data.content;
      this.filteredServices = data.content;
      this.formatServices(this.filteredServices);
      this.isLoading = false;
    }).catch(data => {
      this.globalMessage.message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      this.globalMessage.type = "danger";
      this.isLoading = false;
    });
    this.totalRows = this.items.length;

    this.$root.$on('addTableField', (key, label) => {
      this.addTableField(key, label);
    });

    this.reduceColumnForSmallScreen(415);
  },
  methods: {
    formatServices(services){
      let _services = [];
      services.forEach((service) => {
        this.addDateIfNotExists(service.startDate);
        if((service.productCategoryName === this.filters.storage || service.productCategoryName === this.filters.network || service.productCategoryName === this.filters.database || service.productCategoryName === this.filters.compute || service.productCategoryName === this.filters.others) ||
            (!this.filters.storage && !this.filters.network && !this.filters.database && !this.filters.compute && !this.filters.others) ||
            (this.filters.all)){
          if ((this.statusActivated && service.status === this.status) || !this.statusActivated || this.status === -1){
            if (this.periodActivated && service.startDate.split(this.period).length > 1 || !this.periodActivated)
              _services.push(service);
          }
        }
      });
      this.nativFilteredServices = _services;
    },
    addDateIfNotExists(date){
      date = date.slice(0,7)
      if (this.periodList.findIndex((d)=>d===date) === -1)
        this.periodList.push(date);
    },
    refreshServices(){
      this.formatServices(this.services);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.filteredServices = filteredItems;
    },
    filterAll(){
      this.filters.all = !this.filters.all??" ";
      this.refreshServices();
    },
    filterCompute(){
      if(!this.filters.compute){
        this.filters.compute = "Compute";
      }else
        this.filters.compute = null;
      this.refreshServices();
    },
    filterStorage(){
      if(!this.filters.storage){
        this.filters.storage = "Storage";
      }else
        this.filters.storage = null;
      this.refreshServices();
    },
    filterNetwork(){
      if(!this.filters.network){
        this.filters.network = "Netzwerk";
      }else
        this.filters.network = null;
      this.refreshServices();
    },
    filterDatabase(){
      if(!this.filters.database){
        this.filters.database = "Datenbanken";
      }else
        this.filters.database = null;
      this.refreshServices();
    },
    filterOthers(){
      if(!this.filters.others){
        this.filters.others = "Sonstiges";
      }else
        this.filters.others = null;
      this.refreshServices();
    },
    filterStatus(e){
      if (e.target.type === "checkbox"){
        this.status = -1;
        this.statusActivated = !this.statusActivated;
      }else{
        if(this.statusActivated){
          this.status = parseInt(e.target.value);
        }
      }
      this.refreshServices();
    },
    filterMonth(e){
      if (e.target.type === "checkbox"){
        this.period = !this.periodActivated? this.getCurrentDate() : "";
        this.periodActivated = !this.periodActivated;
      }else{
        if(this.periodActivated){
          this.period = e.target.value;
        }
      }
      this.refreshServices();
    },
    formatPrice(price){
      price = ""+price;
      price = price.split(".");
      if(price.length === 1)
        return price[0] + ",00";
      else{
        if (price[1].length === 1)
          return price[0] + "," + price[1] + "0";
        else
          return price[0] + "," + price[1].slice(0,2)
      }
    },
    getCurrentDate(){
      const date = new Date();
      let month = (date.getUTCMonth() + 1) + "";
      month = month.length > 1? month : "0"+month
      return date.getUTCFullYear() + "-" + month;
    },
    showService(service){
      this.removeTableField("startDate");
      this.service = service;
      this.isViewService = true;
    },

    closeService(){
      this.addTableField("startDate", "Startdatum");
      this.isViewService = false;
    },

    removeTableField(key){
      let newFields = [];
      this.fields.forEach(function (field){
        if (key !== field.key)
          newFields.push(field);
      });
      this.fields = newFields;
    },
    addTableField(key, label){
      let buttonField = this.fields.pop();
      this.fields.push({ key: key, label: label, sortable: true });
      this.fields.push(buttonField);
    },
    reduceColumnForSmallScreen(minSize){
      if (screen.availWidth <= minSize){
        this.fields = [
          { key: "name", label: "Titel", sortable: true },
          { key: "buttons", label: "Aktionen"},
        ]
      }
    },
    exportTableAsCSV(){
      let csv = new ToCSV(this.filteredServices);
      csv.createCSVFile();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert show :variant="globalMessage.type" v-if="globalMessage.message">{{ globalMessage.message }}</b-alert>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="row no-gutters">
        <div :class="isViewService? 'col-md-6' : 'col-md-12'">

          <h2 class="mt-4 ml-1rem">Leistungen</h2>
          <div class="p-3 mt-4 bg-gray">
            <div><a href="" v-on:click="(e)=>{e.preventDefault();isFilterOpen = !isFilterOpen}"><b>Filtern<span v-if="isFilterOpen">:</span></b></a></div>
            <div class="filter" v-show="isFilterOpen">
              <div class="filter-container mb-4 mt-2">
                <span :class="filters.all?'selected':''" class="filter-badge" v-on:click="filterAll">Alle</span>
                <span :class="filters.compute?'selected':''" class="filter-badge" v-on:click="filterCompute">Compute</span>
                <span :class="filters.storage?'selected':''" class="filter-badge" v-on:click="filterStorage">Storage</span>
                <span :class="filters.network?'selected':''" class="filter-badge" v-on:click="filterNetwork">Netzwerk</span>
                <span :class="filters.database?'selected':''" class="filter-badge" v-on:click="filterDatabase">Datenbanken</span>
                <span :class="filters.others?'selected':''" class="filter-badge" v-on:click="filterOthers">Sonstiges</span>
              </div>
              <div class="row mb-4">
                <div class="col-md-3 mb-3">
                  <div class="d-flex">
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          name="period"
                          class="custom-control-input"
                          id="period"
                          v-model="periodActivated"
                          v-on:click="filterMonth"
                      />
                      <label class="custom-control-label" for="period">Startdatum:</label>
                    </div>
                    <select name="" id="" class="ml-4 pr-5"  v-on:change="filterMonth">
                      <option :value="getCurrentDate()">aktueller Monat</option>
                      <option v-for="(period,i) in periodList" v-bind:key="i" :value="period">{{ period }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="d-flex">
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          name="service_status"
                          class="custom-control-input"
                          id="service_status"
                          v-model="statusActivated"
                          v-on:click="filterStatus"
                      />
                      <label class="custom-control-label" for="service_status">Status:</label>
                    </div>
                    <select name="service_status" class="ml-4 pr-5" v-on:change="filterStatus">
                      <option value="-1">(aktiv/beendet)</option>
                      <option value="1">aktiv</option>
                      <option value="0">beendet</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="card">
            <div class="card-body pl-0 ml-1rem">
              <div class="row no-gutters mt-4">
                <div class="col-sm-12 col-md-6 pl-0">
                  <div id="tickets-table_length" class="dataTables_length">
                    <span>Anzeigen</span>
                    <label class="d-inline-flex align-items-center">
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                    </label>
                    <span>pro Seite</span>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      <i class="ri-search-2-line"></i>
                      <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Suche"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <a  @click="exportTableAsCSV"
                        class="ml-2" v-b-tooltip.hover
                        title="Exportieren als CSV">
                      <i class="ri-share-box-line"></i>
                    </a>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="row no-gutters">
                <div class="col-md-12 pl-0">
                  <div class="table-responsive mb-0">
                    <b-table
                        :items="nativFilteredServices"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        ref="table"
                        class="table-hover"
                        show-empty
                    >
                      <!--                  <template #cell(buttons)="row">
                                          <b-button size="sm" @click="showService(row.item)" class="mr-2 btn btn-primary" v-b-tooltip.hover title="Leistung ansehen">
                                            <i class="ri-share-box-line"></i> mehr
                                          </b-button>
                                        </template>-->
                      <template #cell(productLink)="row">
                        <a :href="row.item.productLink" v-if="row.item.productLink" target="_blank" v-b-tooltip.hover title="Öffnen">
                          <i class="ri-share-box-line"></i>
                        </a>
                      </template>
                      <template #cell(productGroupName)="row">
                        <span>
                          <i class="ri-loader-2-fill" v-if="row.item.productCategoryName==='Compute'"></i>
                          <i class="ri-hard-drive-2-line" v-if="row.item.productCategoryName==='Storage'"></i>
                          <i class="ri-arrow-left-right-line" v-if="row.item.productCategoryName==='Netzwerk'"></i>
                          <i class="ri-database-2-fill" v-if="row.item.productCategoryName==='Datenbanken'"></i>
                          <i class="ri-settings-3-fill" v-if="row.item.productCategoryName==='Sonstiges'"></i>
                          {{ row.item.productName }}
                        </span>
                      </template>
                      <template #cell(startDate)="row">
                        {{ row.item.startDate }}<span v-if="row.item.endDate"> | {{ row.item.endDate }}</span>
                      </template>
                      <template #cell(rate)="row">
                        {{ row.item.rate }} Euro
                      </template>
                      <template #cell(status)="row">
                        <span v-if="row.item.status===1">aktiv</span>
                        <span v-if="row.item.status===0">beendet</span>
                      </template>
                      <template #empty>
                        <p class="text-center">Keine Leistungen vorhanden</p>
                      </template>
                      <template #emptyfiltered>
                        <p class="text-center">Es gibt keine Einträge zu Ihrer Anfrage</p>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 serviceViewer" v-if="isViewService">
          <ServiceDetails :service.sync="service" :is-view-service.sync="isViewService" />
        </div>
      </div>
    </b-overlay>

  </Layout>
</template>

<style>
.filter select{
  border-color: #c8c8cf;
}
@media screen and (max-width: 766px)  {
  .serviceViewer{
    margin-top: 10px;
  }
}
</style>
