<script>
export default {
  name: "service-details",
  props: {
    service: {},
    isViewService: Boolean,
  },
  mounted(){
    this.$refs.linkDetails.click();
  },
  created() {
    //this.$refs.linkDetails.click();
  },
  methods: {
    closeEditBox(){
      this.$emit('update:isViewService', false);
      this.$root.$emit('addTableField', "startDate", "Startdatum");
    }
  }
}
</script>

<template>
  <div class="card" id="detailsCart">
    <a href="#detailsCart" ref="linkDetails"></a>
    <div class="card-body">
      <div class="cart-title">
        <div class="row mb-2">
          <div class="col-6"><h4>Details</h4></div>
          <div class="col-6"><b-button @click="closeEditBox" size="sm" class="float-right btn-danger" ><i class="ri-close-line"></i></b-button></div>
        </div>
      </div>
      <hr>
      <div class="card-body">
        <h5>{{ service.name }}</h5>
        <p>
          Das Projekt hat am <b>{{ service.startDate }}</b> angefangen
          <span v-if="service.endDate"> und hat sich am <b> {{ service.endDate }} </b> geendet</span>
        </p>
        <h5>Weitere Informationen</h5>
        <table class="table table-striped table-responsive">
          <tr>
            <th>UUID</th>
            <td>{{ service.uuid }}</td>
          </tr>
          <tr>
            <th>nächstes Fälligkeitsdatum</th>
            <td>{{ service.nextDueDate }}</td>
          </tr>
          <tr>
            <th>Abrechnungsintervall</th>
            <td>{{ service.nextDueDate }}</td>
          </tr>
          <tr>
            <th>Zustand</th>
            <td v-if="service.status===0">Abgebrochen</td>
            <td v-if="service.status===1">Aktiv</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style>
.card{
  height: 100%;
}
</style>